.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ContentArea {
  display: flex;
  flex-direction: row;
  height: 97vh;
  overflow-y: hidden;
}